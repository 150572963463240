<template>
  <div style="min-width:1290px;">
    <MyBanner />
    <div>
      <div class="header-img">
        <div class="title-img">
          <div style="font-size: 42px;font-weight: 500;color: rgba(0, 82, 217, 1);position: relative;">
            <div>智能投保平台</div>
            <div style="width: 380px;position: absolute;bottom: -40px;left: -60px;"><img src="./../static/zntb.png" alt=""></div>
          </div>
          <div style="font-size: 24px;font-weight: 400;color: rgba(102, 102, 102, 1);margin-top:10px;">全流程在线办理 极速出函</div>
        </div>
        <div class="title-tip w1300">
          <div style="display: flex;justify-content: space-between;">
            <div v-for="(item,index) in titleArr" :key="item.id" style="width: calc(100% / 5);display: flex;flex-direction: column;align-items: center;">
              <div style="width: 75px;height: 52px;"><img :src="item.url" alt="" style="object-fit:none;margin-left: 5px;" :style="index==2?'margin-left: 15px;':''"></div>
              <div style="display: flex;align-items: center;padding-top:15px;">
                <div style="width: 120px;height: 0px;border: 1px dashed rgb(42, 123, 234);" :style="index>1?'border: 1px dashed rgb(166,166,166);':''"></div>
                <div style="width: 16px;height: 16px;background: rgb(42, 123, 234);border-radius:50%;" :style="index>1?'background: rgb(215, 215, 215);':''"></div>
                <div style="width: 120px;height: 0px;" :style="index>0?'border: 1px dashed rgb(166,166,166);':'border: 1px dashed rgb(42, 123, 234);'"></div>
              </div>
              <div style="padding-top:15px;font-size: 18px;color: rgb(69, 87, 124);" :style="index>1?'color: rgb(215, 215, 215);':''">{{item.title}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="SignUpForms">
        <div class="SignUpForms-box w1300">
          <div style="display:flex;justify-content: center;align-items: center;flex-direction: column;">
            <div><img src="./../static/success.png" alt=""></div>
            <div style="font-size: 20px;color: rgba(32, 35, 50, 1);margin-top:24px;">提交成功</div>
            <div style="font-size:14px;color: rgba(79, 84, 112, 1);margin-top:20px;">您可以返回【我的订单】进行查看、编辑等相关操作</div>
            <div class="btn" @click="looking">查看个人中心</div>
          </div>
        </div>
      </div>
    </div>
    <MyFooter />
  </div>
</template>
<script setup>
import MyBanner from "@/components/MyBanner.vue";
import MyFooter from "@/components/MyFooter.vue";
import { useRouter } from "vue-router";
import { ref, onMounted } from "vue";
let router = useRouter();
let titleArr = ref([
  { id: 1, url: require('./../static/step1.png'), title: '项目信息申报' },
  { id: 3, url: require('./../static/step3.png'), title: '保证金核定' },
  { id: 5, url: require('./../static/ico-04.png'), title: '三制落实' },
  { id: 6, url: require('./../static/ico-05.png'), title: '季度申报' },
  { id: 7, url: require('./../static/ico-02.png'), title: '工资监管' },
])
let looking = () => {
  router.push("/personalCenter")
  // const currentUrl = window.location.href;
  // const parts = currentUrl.split('/');
  // const baseUrl = `${parts[0]}//${parts[2]}`;
  // window.open(`${baseUrl}` + '/' + `personalCenter`);
  localStorage.setItem('active', '2');
}
</script>
<style scoped>
.btn {
  border-radius: 4px;
  background: linear-gradient(
    135deg,
    rgba(31, 112, 247, 1) 0%,
    rgba(85, 155, 254, 1) 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 16px 7px 16px;
  box-sizing: border-box;
  color: #fff;
  margin-top: 30px;
  cursor: pointer;
}
.SignUpForms {
  width: 100%;
  height: calc(100vh - 580px);
  background: rgb(246, 247, 250);
}
.SignUpForms-box {
  padding-top: 90px;
  box-sizing: border-box;
}
img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.header-img {
  width: 100%;
  height: 340px;
  background: url("./../static/banner-pub.png") no-repeat center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  position: relative;
}
.title-img {
  width: 360px;
  height: 119px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title-tip {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -75px;
  border-radius: 6px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 0px rgba(17, 17, 26, 0.05),
    0px 0px 8px 2px rgba(0, 82, 217, 0.1);
  padding: 20px 0;
  box-sizing: border-box;
}
.w1300 {
  width: 1300px;
  margin: 0 auto;
}
</style>
